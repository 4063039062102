import React, { useState } from 'react';

import css from './SectionHowItWorks.module.css';
import { FormattedMessage } from 'react-intl';
import browseIcon from './images/browse.png';
import doneIcon from './images/done.png';
import verifiedIcon from './images/verified.png';
import sellIcon from './images/sell.png';
import classNames from 'classnames';

const SectionHowItWorks = () => {
    const [currentTab, setCurrentTab] = useState('professionals');

    const Professionals = (
        <div className={css.panelWrapper}>
            <p className={css.subtitle}>
                <FormattedMessage id="SectionHowItWorks.professionals.title" />
            </p>
            <div className={css.line} />
            <div className={css.horizontalWrapper}>
                <div>
                    <div className={css.verticalWrapper}>
                        <img className={css.icon} src={verifiedIcon} alt="Verified" />
                        <p className={css.subtitle}>
                            <FormattedMessage id="SectionHowItWorks.professionals.signup" />
                        </p>
                    </div>
                    <div className={css.verticalWrapper}>
                        <img className={css.icon} src={sellIcon} alt="Sell" />
                        <p className={css.subtitle}>
                            <FormattedMessage id="SectionHowItWorks.professionals.sell" />
                        </p>
                    </div>
                </div>
                <div>
                    <div className={css.verticalWrapper}>
                        <img className={css.icon} src={browseIcon} alt="Browse" />
                        <p className={css.subtitle}>
                            <FormattedMessage id="SectionHowItWorks.professionals.browse" />
                        </p>
                    </div>
                    <div className={css.verticalWrapper}>
                        <img className={css.icon} src={doneIcon} alt="Done" />
                        <p className={css.subtitle}>
                            <FormattedMessage id="SectionHowItWorks.professionals.getAppointed" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );

    const Client = (
        <div className={css.panelWrapper}>
            <p className={css.subtitle}>
                <FormattedMessage id="SectionHowItWorks.clients.title" />
            </p>
            <div className={css.line} />
            <div className={css.horizontalWrapper}>
                <div>
                    <div className={css.verticalWrapper}>
                        <img className={css.icon} src={verifiedIcon} alt="Verified" />
                        <p className={css.subtitle}>
                            <FormattedMessage id="SectionHowItWorks.clients.signup" />
                        </p>
                    </div>
                    <div className={css.verticalWrapper}>
                        <img className={css.icon} src={sellIcon} alt="Post" />
                        <p className={css.subtitle}>
                            <FormattedMessage id="SectionHowItWorks.clients.post" />
                        </p>
                    </div>
                </div>
                <div>
                    <div className={css.verticalWrapper}>
                        <img className={css.icon} src={browseIcon} alt="Browse" />
                        <p className={css.subtitle}>
                            <FormattedMessage id="SectionHowItWorks.clients.browse" />
                        </p>
                    </div>
                    <div className={css.verticalWrapper}>
                        <img className={css.icon} src={doneIcon} alt="Hire" />
                        <p className={css.subtitle}>
                            <FormattedMessage id="SectionHowItWorks.clients.hire" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className={css.root}>
            <div className={css.subtitleWrapper}>
                <p className={css.subtitle}>
                    <FormattedMessage id="SectionHowItWorks.subtitle" />
                </p>
                <p className={css.title}>
                    <FormattedMessage id="SectionHowItWorks.title" />
                </p>
            </div>
            <div className={css.mobileTab}>
                <div className={classNames(css.mobileFlex, css.mobileWf)}>
                    <p
                        className={classNames(css.subtitle, { [css.active]: currentTab === 'professionals' })}
                        onClick={() => setCurrentTab('professionals')}
                    >
                        <FormattedMessage id="SectionHowItWorks.professionals.title" />
                    </p>
                    <p
                        className={classNames(css.subtitle, { [css.active]: currentTab === 'client' })}
                        onClick={() => setCurrentTab('client')}
                    >
                        <FormattedMessage id="SectionHowItWorks.clients.title" />
                    </p>
                </div>
                <div className={classNames(css.horizontalTabWrapper, css.horizontalWrapper, css.mobile)}>
                    {currentTab === 'professionals' ? Professionals : Client}
                </div>

                <div className={classNames(css.horizontalTabWrapper, css.horizontalWrapper, css.desktop)}>
                    {Professionals}{Client}
                </div>
            </div>
        </div>
    );
};

export default SectionHowItWorks;
