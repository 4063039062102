/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import {
  AvatarLarge,
  InlineTextButton,
  LinkedLogo,
  NamedLink,
  NotificationBadge,
} from '../../../components';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    appConfig,
    isDark,
    intl
  } = props;

  const marketplaceName = appConfig.marketplaceName;
  const user = ensureCurrentUser(currentUser);

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.logoWrapper}>
          <LinkedLogo
            className={css.logoLink}
            isDark={isDark}
            format="desktop"
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
          />
        </div>
        <div className={css.content}>
          <div className={css.linksWrapper}>
            <div className={classNames(css.navLink)}>
              <NamedLink
                name="LandingPage"
                className={currentPage === 'LandingPage' ? css.linkCurrent : css.link}
              >
                <span className={css.linkText}>
                  <FormattedMessage id="TopbarDesktop.whatIs" />
                </span>
              </NamedLink>
            </div>
            <div className={classNames(css.navLink)}>
              <NamedLink
                name="LandingClientsPage"
                className={currentPage === 'LandingClientsPage' ? css.linkCurrent : css.link}
              >
                <span className={css.linkText}>
                  <FormattedMessage id="TopbarDesktop.clients" />
                </span>
              </NamedLink>
            </div>
            <div className={classNames(css.navLink)}>
              <NamedLink
                name="LandingProfessionalsPage"
                className={currentPage === 'LandingProfessionalsPage' ? css.linkCurrent : css.link}
              >
                <span className={css.linkText}>
                  <FormattedMessage id="TopbarDesktop.professionals" />
                </span>
              </NamedLink>
            </div>
            <div className={classNames(css.navLink)}>
              <a href="/blog.html">
                <div className={css.link}>
                  <span className={css.linkText}>
                    <FormattedMessage id="TopbarDesktop.blog" />
                  </span>
                </div>
              </a>
            </div>
          </div>
          <div className={css.buttonWrapper}>
            <NamedLink name="LoginPage" className={css.loginLink}>
              <span className={css.login}>
                <FormattedMessage id="TopbarDesktop.signIn" />
              </span>
            </NamedLink>
            <NamedLink name="SignupPage" className={css.signupLink}>
              <span className={css.signup}>
                <FormattedMessage id="TopbarDesktop.join" />
              </span>
            </NamedLink>
          </div>
        </div>
        {/* <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div> */}
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isContactDetailsPage =
      page === 'ContactDetailsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isContactDetailsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ContactDetailsPage'))}
          name="ContactDetailsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <div className={css.spacer} />
      </div>
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
