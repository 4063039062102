import React from 'react';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { NamedLink, AsideCardBlured, LineDivider } from '../../../components';
import ProfileCompletionBanner from '../../ProfileSettingsPage/ProfileCompletionBanner/ProfileCompletionBanner';

import tipIcon from './images/tip_icon.png';
import closeIcon from './images/close_icon.png';

import css from './TipsPanel.module.css';

const TipsPanelComponent = props => {
  const { currentStep, handleCloseTipsPanel, currentUser } = props;
  return (
    <AsideCardBlured>
      <div>

        <ProfileCompletionBanner currentUser={currentUser} isSearchPage={true} />
        {currentUser?.id?.uuid &&
          <NamedLink
            name="ProfilePage"
            params={{ id: currentUser?.id?.uuid }}
            className={css.buttonLink}
          >
            <FormattedMessage id={'TipsPanel.searchPage.profileButton'} />
          </NamedLink>
        }
        <div className={css.horizontalWrapper}>
          <div className={css.horizontalWrapper}>
            <img className={css.tipIcon} src={tipIcon} />
            <div className={css.contentTip}>
              <p className={css.tip}>
                <FormattedMessage id={'TipsPanel.proTip'} />
              </p>
              <h1 className={css.title}>
                <FormattedMessage id={'TipsPanel.searchPage.title'} />
              </h1>
            </div>
          </div>

          {/* <img className={css.closeIcon} src={closeIcon} onClick={handleCloseTipsPanel} /> */}
        </div>

        <LineDivider white />

        <div className={css.infoWrapper}>
          <FormattedMessage id={'TipsPanel.searchPage.tip'} />
        </div>
        <p className={css.moreTips}>
          <FormattedMessage id={'TipsPanel.moreTips'} />
        </p>
      </div>
    </AsideCardBlured>
  );
};

const TipsPanel = compose(injectIntl)(TipsPanelComponent);
TipsPanel.displayName = 'TipsPanel';

export default TipsPanel;
