import React from 'react';

import css from './SectionInfo.module.css';
import { FormattedMessage } from 'react-intl';
import { Button, NamedLink } from '../../../../components';
import hireImage from './images/hire.png';
import becomeImage from './images/become.png';
import classNames from 'classnames';

const SectionInfo = props => {
  return (
    <div className={css.root}>
      <div className={classNames(css.horizontalWrapper,css.horizontalReverse)}>
        <img className={css.image} src={becomeImage} /> 
        <div className={classNames(css.verticalWrapper,css.verticalHolder)}>
          <p className={css.title}>
            <FormattedMessage id="SectionInfo.become.title" />
          </p>

          <p className={css.info}>
            <FormattedMessage id="SectionInfo.become.info1" />

            <NamedLink name="SignupPage" className={css.infoHighlight}>
              <FormattedMessage id="SectionInfo.become.info2" />
            </NamedLink>
          </p>

          <Button>
            <FormattedMessage id="SectionInfo.learnMore" />
          </Button>
        </div>
      </div>

      <div className={classNames(css.horizontalWrapper,css.horizontalReverseImg)}>
        <div className={css.verticalWrapper}>
          <p className={css.title}>
            <FormattedMessage id="SectionInfo.hire.title" />
          </p>

          <p className={css.info}>
            <FormattedMessage id="SectionInfo.hire.info" />
          </p>

          <Button>
            <FormattedMessage id="SectionInfo.learnMore" />
          </Button>
        </div>

        <img className={css.image} src={hireImage} />
      </div>
    </div>
  );
};

export default SectionInfo;
