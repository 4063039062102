import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const MenuIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootMenuIcon, className);

  return (
    <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1.5H15" stroke="white" stroke-width="1.5" stroke-linecap="round" />
      <path d="M1 7.5H20.9998" stroke="white" stroke-width="1.5" stroke-linecap="round" />
      <path d="M1 13.5L11 13.5" stroke="white" stroke-width="1.5" stroke-linecap="round" />
    </svg>
  );
};

const { string } = PropTypes;

MenuIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

MenuIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default MenuIcon;
